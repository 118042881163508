import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-survey-build',
	templateUrl: './build.component.html',
	styleUrls: ['./build.component.scss']
})
export class SurveyBuildComponent implements OnInit {
	constructor() { }

	ngOnInit(): void { }
}
