import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-case-studies',
	templateUrl: './case-studies.component.html',
	styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesPageComponent implements OnInit {

  	constructor() { }
	ngOnInit(): void {	}
}
