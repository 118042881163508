<div class="container-fluid theme-light">
	<div class="container">
		<div class="d-flex flex-column border">
			<h2 class="text-center">
				Where would you like to begin with your build?
			</h2>
			<label class="d-block mat-h4 text-center">
				Let's start with&hellip;
			</label>
			<div class="d-flex flex-row flex-wrap align-items-center mb-5">
				<div class="flex-fill d-flex flex-column">
					<mat-chip-list selectable="true" multiple="false" #intentSlider aria-label="" aria-orientation="vertical">
						<mat-chip class="col justify-content-center border bg-white text-dark mat-stroked-button mat-raised-button"
							#evaluateData="matChip" color="warn">
							Help me understand and evaluate my data
						</mat-chip>
						<mat-chip class="col justify-content-center border bg-white text-dark mat-stroked-button mat-raised-button"
							#biasInPipeline="matChip" color="warn">
							Show me where I might find bias along the ML pipeline
						</mat-chip>
						<mat-chip class="col justify-content-center border bg-white text-dark mat-stroked-button mat-raised-button"
							#buyBuild="matChip" color="warn">
							Help me make a buy/build decision
						</mat-chip>
						<mat-chip class="col justify-content-center border bg-white text-dark mat-stroked-button mat-raised-button"
							#modelDeployment="matChip" color="warn">
							Walk me through model deployment and beyond
						</mat-chip>
					</mat-chip-list>
				</div>
			</div>
		</div>
	</div>
</div>