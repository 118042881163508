<section id="toolkit" class="container-fluid theme-gradient padding-xl-top padding-xl-bottom theme-dark mat-app-background mat-typography">
    <div class="container d-md-flex justify-content-start align-content-stretch dec-line-vertical top right">
        <div class="col-md-6  padding-lg-top padding-lg-bottom dec-line pink bottom right offset-bottom offset-right">
            <h1>
                AI/ML Anti-Bias Toolkit
				<small class="d-block font-body font-weight-normal mat-subheading-1">
					Tools for serving communities better when leveraging AI/ML in public sector services
				</small>
            </h1>
        </div>
        <div class="col-md-6 decoration"></div>
    </div>
</section>
<section class="container-fluid padding-lg-top padding-lg-bottom theme-light mat-app-background mat-typography">
    <div class="container">
        <div class="d-flex justify-content-end">
            <div class="col-md-8 dec-line-vertical dotted top left offset-top offset-left">
                <h2>Building AI/ML Right</h2>
                <p class="mat-subheading-1 dec-line green top left offset-left">
                    Whether you are a city analytics lead, a state chief data officer, or federal agency director, this toolkit offers the tools for you to
                    drive impact in building ethical and equitable AI/ML for the communities you serve.
                </p>
                <a
						class="d-inline-block mt-5"
						mat-raised-button mat-stroked-button
						routerLink="/Resources">
                    <span>Get <span class="d-none d-sm-inline">directed to relevant</span> resources</span>
                    <mat-icon color="accent" class="round thick-border"><app-icon-next></app-icon-next></mat-icon>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="container-fluid pt-5 pb-5 theme-medium mat-app-background mat-typography">
    <div class="container">
        <h3 class="dec-line pink mb-5 bottom left offset-bottom ">Featured Resources</h3>
        <div class="d-flex flex-wrap pt-5 align-items-stretch mb-5">
            <div class="col-md-4 flex-column mb-5">
                <mat-card class="h-100 d-flex flex-column text-center">
					<mat-card-header class="justify-content-center mt-5 mb-5">
						<mat-icon color="warn">public</mat-icon>
						<mat-icon>folder_open</mat-icon>
					</mat-card-header>
					<mat-card-content class="flex-fill d-flex flex-column justify-content-end">
						<h4 class="font-accent">
							International Resources
						</h4>
						<p>
							about data, AI/ML, and bias
						</p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex flex-row justify-content-end align-items-center">
						<button mat-mini-fab aria-label="" color="accent" class="bg-light thick-border">
							<mat-icon color="accent"><app-icon-next></app-icon-next></mat-icon>
						</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="col-md-4 flex-column mb-5">
				<mat-card class="h-100 d-flex flex-column text-center">
					<mat-card-header class="justify-content-center mt-5 mb-5">
						<mat-icon>content_copy</mat-icon>
						<mat-icon color="warn">search</mat-icon>
					</mat-card-header>
                    <mat-card-content class="flex-fill d-flex flex-column justify-content-between">
                        <h4 class="font-accent">
                            Case Studies
                        </h4>
						<p>
							about data, AI/ML, and bias
						</p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex flex-row justify-content-end align-items-center">
						<button mat-mini-fab aria-label="" color="accent" class="bg-light thick-border">
							<mat-icon color="accent"><app-icon-next></app-icon-next></mat-icon>
						</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="col-md-4 flex-column mb-5">
                <mat-card class="h-100 d-flex flex-column text-center">
					<mat-card-header class="justify-content-center mt-5 mb-5">
						<mat-icon color="warn">chevron_left</mat-icon>
						<mat-icon>view_list</mat-icon>
						<mat-icon color="warn">chevron_right</mat-icon>
					</mat-card-header>
                    <mat-card-content class="flex-fill d-flex flex-column justify-content-between">
                        <h4 class="font-accent">
                            Technical Tools for Mitigating Bias
                        </h4>
                    </mat-card-content>
                    <mat-card-actions class="d-flex flex-row justify-content-end align-items-center">
						<button mat-mini-fab aria-label="" color="accent" class="bg-light thick-border">
							<mat-icon color="accent"><app-icon-next></app-icon-next></mat-icon>
						</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</section>