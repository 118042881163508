<!-- <div id="readme" class="resource col-md-6 col-lg-8 mb-5 theme-lightgreen">
	<app-resource
		resourceIdentifier="{{ survey.intent }}/{{ survey.ai}}/Readme"
		[classes]="['h-100', 'd-flex', 'flex-column']"
		[tags]="[survey.intent, survey.ai]">
	</app-resource>
</div> -->
<ng-template #surveyActionCard>
	<ng-template #surveyCallToActionTitle>Bias Your Results</ng-template>
	<ng-template #surveyCallToActionText>Filter the resources on this page by taking our 60-second quiz</ng-template>
	<ng-template #surveyCallToActionButton>Take the Quiz</ng-template>

	<ng-template #surveyResultsTitle>Rebias Your Results</ng-template>
	<ng-template #surveyResultsText>Want to try again? Filter the resources on this page by taking our 60-second quiz</ng-template>
	<ng-template #surveyResultsButton>Retake the Quiz</ng-template>

	<div class="resource action theme-lightpink offset-background col-md-6 col-lg-4 mb-5">
		<mat-card>
			<mat-card-header>
				<mat-card-title>
					<span class="font-accent mat-subheading-1 font-weight-bold">
						<ng-content *ngIf="surveyData; then surveyResultsTitle; else surveyCallToActionTitle"></ng-content>
					</span>
				</mat-card-title>
				<mat-chip-list aria-label="tags" class="offset-background">
					<mat-chip class="text-accent">New</mat-chip>
				</mat-chip-list>
			</mat-card-header>
			<mat-card-content>
				<p>
					<ng-content *ngIf="surveyData; then surveyResultsText; else surveyCallToActionText"></ng-content>
				</p>
			</mat-card-content>
			<mat-card-actions>
				<button class="text-light" color="primary" mat-raised-button routerLink="/quiz">
					<span>
						<ng-content *ngIf="surveyData; then surveyResultsButton; else surveyCallToActionButton"></ng-content>
					</span>
				</button>
			</mat-card-actions>
		</mat-card>
	</div>
</ng-template>
<ng-template #highlightCard>
	<div class="resource highlight col-md-6 col-lg-8 mb-5 theme-lightgreen">
		<mat-card>
			<mat-card-header>
				<mat-card-title>
					<span class="font-accent mat-title font-weight-bold">
						Highlight Title
					</span>
				</mat-card-title>
				<mat-chip-list aria-label="tags" class="offset-background">
					<mat-chip class="text-accent">Awesome!</mat-chip>
				</mat-chip-list>
			</mat-card-header>
			<mat-card-content>
				<p>
					This content could be hardcoded w/ basic logic (build vs learn, for example),
					and/or could be pulled in from a markdown file from github.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button mat-stroked-button>
					<span>Call to Action or Link to Resource</span>
				</button>
			</mat-card-actions>
		</mat-card>
	</div>
</ng-template>
<ng-template #results>
	<div *ngFor="let filename of resourceIdentifiers[surveyData.intent]" class="resource list theme-lightgreen col-12">
		<!-- resourceIdentifier="{{survey.displayNames[surveyData.intent]}}/{{surveyData.ai}}/{{filename}}" -->
		<app-resource
			[list]="true"
			[tags]="[survey.displayNames[surveyData.intent]]"
			[title]="'Markdown File: ' + filename">
		</app-resource>
	</div>
</ng-template>
<ng-template #all>
	<ng-container *ngFor="let topic of objectKeys(resourceIdentifiers)">
        
		<ng-container *ngFor="let level of survey.levels">
			<div *ngFor="let filename of resourceIdentifiers[topic]" class="resource list theme-lightgreen col-12 mb-3">
				<!-- NOTE: I am showing the filename as title while there is likely to be empty file content. 	-->
				<!-- This, in combo with tags, allows us to see what resource we are are looking for. 			-->
				<!-- Any title provided on this side should become unecessary once content is complete. 		-->
				<app-resource
					[list]="true"
					[tags]="[displayNames[level], displayNames[topic]]"
					resourceLink="resource/{{ displayNames[topic] }}/{{ displayNames[level] }}/{{filename}}"
					[title]="filename">
				</app-resource>
			</div>
		</ng-container>
	</ng-container>
</ng-template>
<div class="container-fluid">
	<!-- full width -->
	<section class="container">
		<!-- max 1024 -->
		<div id="resources" class="d-md-flex flex-wrap justify-content-evenly align-items-stretch">
			<ng-template *ngIf="surveyActionCard; then surveyActionCard"></ng-template>
			<ng-template *ngIf="highlightCard; then highlightCard"></ng-template>
			<ng-container *ngIf="surveyData; then results; else all"></ng-container>
		</div>
	</section>
</div>
